// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

console.log("Starting to execute: application.js");
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import "jquery"
import {} from "jquery-ujs"
import "@popperjs/core"
import "bootstrap"
import "channels"
// import "leaflet"

require ("bootstrap/scss/bootstrap.scss")
// require ("leaflet/dist/leaflet.css")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import * as echarts from "echarts";
import "echarts/theme/dark";

window.echarts = echarts;

console.log("FINISHED LOADING: application.js");
